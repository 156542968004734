<!-- eslint-disable -->
<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="blue-grey lighten-3"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      @click="checklogin"
      to="/sitehome"
    >
      <v-avatar>
          <img
            src="../../assets/inprem_favicon_circle.png"
          class="align-self-center"
          color="white"
          contain
          />
      </v-avatar>
    </v-btn>

<!-- TITLE HERE -->
  <h1>
    Intraprem
  </h1>
    <v-spacer />
    <v-toolbar-title
      class="font-weight-regular"
      v-text="currentsite.name"
    />
    <v-spacer />

<!-- *************** Help / About Dialog ******************** 
     *** Raises a modal info dialog with Product and Version Info  -->

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
    </v-menu>
<!-- *************** Settings Dialog ******************** 
     *** Raises a modal info dialog with a list of key settings / modes 
     ***   Primarily for developers who can see a quick overview of the 
     ***   config file default.toml  -->
<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{on: tooltip}">
            <v-btn
              class="ml-2"
              min-width="0"
              text
              v-bind="attrs"
              v-on="{ ...on, ...tooltip }"
              @click="initsettings"
            >
            <v-icon large>mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>Settings</span>
        </v-tooltip>  
      </template>

      <v-card
        class="mx-auto">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Settings
        </v-list-item-title>
        <v-list-item-subtitle>Run Mode Configuration</v-list-item-subtitle>
      </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>{{r_runmode_prod}}</v-list-item-title>
      <v-list-item-title>{{r_runmode_dbmode}}</v-list-item-title>
      <!-- <v-list-item-subtitle>{{r_user}}</v-list-item-subtitle> -->
      <v-list-item-subtitle>{{r_host}}</v-list-item-subtitle>
      <!-- <v-list-item-subtitle>{{r_port}}</v-list-item-subtitle>
      <v-list-item-subtitle>{{r_today_override}}</v-list-item-subtitle>
      <v-list-item-subtitle>{{r_use_proxy}}</v-list-item-subtitle> -->
    </v-list-item-content>
    </v-list-item>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Dismiss
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>     
    
<!-- User Btn with drop down list of actions -->

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-list-item-avatar
          v-if="loggedin.state"
          class="mt-n2"
          elevation="1"
          fab
          small
          v-bind="attrs"
          v-on="on"
        >
          <img
            src="../../assets/md-account-loggedin.png"
            center
          />
            <!-- src="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg" -->
            <!-- src="https://demos.creative-tim.com/material-dashboard-pro/assets/img/faces/avatar.jpg" -->
        </v-list-item-avatar>
        <v-list-item-avatar
          v-else
          class="mt-n2"
          elevation="1"
          fab
          small
          v-bind="attrs"
          v-on="on"
        >
          <img
            src="../../assets/md-account.png"
            center
          />
        </v-list-item-avatar>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template v-for="(p, i) in profile">
          <app-bar-item
            v-if="loggedin.state && p.id === 1"
            :key="`profile-${i}`"
            :to="p.to"
          >
            <v-list-item-title
              v-text="p.title"
            />
          </app-bar-item>
          <!-- Login / Profile drop down list   -->
          <app-bar-item
            v-if="!loggedin.state && p.id === 2"
            :key="`profile-${i}`"
            :to="p.to"
          >
            <v-list-item-title
              @click="loginout(true)"
              v-text="p.title"
            />
          </app-bar-item>

          <app-bar-item
            v-if="loggedin.state && p.id === 3"
            :key="`profile-${i}`"
            :to="p.to"
          >
            <v-list-item-title
              @click="loginout(false)"
              v-text="p.title"
            />
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
/* eslint-disable */
//************************
// JAVASCRIPT
//************************
  import { VHover, VListItem } from 'vuetify/lib'
  import CrmService from '../../services/CrmService'
  import ConfigService from '../../services/ConfigService'
  import DataSourceService from '../../services/DataSourceService'
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'PVAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },

    },

    data: () => ({
      dialog: false,
      r_runmode_prod: 'Using Vue Development Server',
      r_runmode_dbmode: 'DB Mode = [dbServer_Dev]',
      r_user: 'root',
      r_host: 'localhost',
      r_port: '3306',
      r_today_override: '',
      r_use_proxy: true,
      sites: [],
      sitename: 'Please Select a Site',
      profile: [
        // { title: 'Settings' },
        // { divider: true },
        { title: 'Log in', id: 2, login2: 'true', to: '/login' },
        { title: 'Log out', id: 3, login2: 'false', to: '/login' },
        { title: 'Profile', id: 1, login2: 'true', to: '/userprofile' },
      ],
      currentId: null,
    }),

//************************
// COMPUTED
//************************
    computed: {
      ...mapState(['drawer', 'loggedin', 'current_site']),
      loggedin: {
        get () {
          return this.$store.state.loggedin
        },
      },
      currentsitename:  {
        get () {
          const cs = this.$store.state.current_site
          if (cs != null) {return cs.siteName}
          return ''
        },
      },
      currentsite: {
        get () {
          return this.$store.state.current_site
        },
        set (val) {
          // this.$store.state.current_site = val
          this.$store.commit('SET_CURRENT_SITE', val)
        }
      }
    },

//************************
// WATCH
//************************
  // watch: {
  //   "$store.state.current_site": {
  //       handler: function(newval) { 
  //         console.log('AppBar: watch currentsite = ', newval.siteName)
  //         this.sitename=newval.siteName
  //         // ebus.$emit('updateSite',newval) // could be used potentially
  //         },
  //       immediate: null
  //     },
  //   },

//************************
// METHODS
//************************
   methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
      setLogin: 'SET_LOGIN',
      setCurrentSite: 'SET_CURRENT_SITE'
    }),

      loginout(loggingin) {
      if(loggingin) {
        // Login here (just nav to the login page)
        if (this.route.name == '/login') return;
        this.$router.push('/login')
      } else {  
        // Logout here
        this.$store.state.loggedin.state = false
        this.$store.state.loggedin.userUid = null
        this.$store.state.loggedin.firstName = null
        this.$store.state.loggedin.lastName = null
        
        this.$store.state.current_customer.customerUid = null
        this.$store.state.current_customer.organizationName = null

        this.$store.state.current_site.uid = null
        this.$store.state.current_site.name = null
        
        this.$router.push('/login')
    } },

    checklogin () {
      if (!this.$store.state.loggedin.state) {
        alert ("Need to log in to access your data.")
      }
    }, 

    async initsettings() {
      try {
        const config = await ConfigService.settingsGetOverview()
        this.r_runmode_prod = config.prod ? 'Production mode - Serving Static Content' : 'Using Vue Development Server'
        this.r_runmode_dbmode = 'db Mode = [' + config.dbMode + ']'
        this.r_user = 'db User = ' + config.user
        this.r_host = 'db Host = ' + config.host
        this.r_port = 'db Port = ' + config.port
        this.r_today_override = 'todayOverride = ' + config.todayOverride
        this.r_use_proxy = 'use Proxy = ' + config.useProxy
      } catch (err) {
        this.error = err.message
        console.log('created: err= ', this.error)
      }
    }
  },
 //***** NON-METHODS *******/
created () {
  // const config = await DataSourceService.getConfigFile()

// Initialize sites data for AppBar dropdown
  // try {
  //   // const s = this.$store.state.current_site.siteName
  //   this.sitename = this.$store.state.current_site.siteName
  //   let sites = await CrmService.siteGetAll()
  //   this.sites = sites
  //   this.initsettings()
  // } catch(err) {
  //     this.error = err.message
  //     console.log('created: err= ', this.error)
  // }
}
}
</script>
